export function makeHelpers(fetchAndSet, info, t, setErrors, navigate, resource_route) {
    const setError = (error) => {
        let actual_field = error.field.split("[").at(0)
        error.field = actual_field
        setErrors(errors => ({ ...errors, [error.field]: error }))
    }
    const handleResponseErrors = async (errors) => {
        if (!(errors.data instanceof Array)) {
            return info(t("Unknown error"), 'danger')
        }
        for (let error of errors.data) {
            if (error?.field) {
                setError(error)
            }
            else {
                console.error("Malformed error", errors, error)
            }
        }
    }
    const handleUpdateResponse = async (value) => {
        if (value.is_error) {
            handleResponseErrors(value.error)
        }
        else {
            await fetchAndSet();
            info("Updated", "success")
        }
    }
    const handleCreateResponse = async (value) => {
        if (value.is_error) {
            handleResponseErrors(value.error)
        }
        else {
            if (!value.data.id) {
                return info(t("Unknown error"), 'danger')
            }
            else {
                navigate(`/${resource_route}/${value.data.id}`)
                info("Created", "success")
            }
        }
    }
    return { setError, handleResponseErrors, handleUpdateResponse, handleCreateResponse }
}