import { useNavigate, useSearchParams } from "react-router-dom";
import { useLanguageContext } from "../../../context/LanguageContext.js";
import { makeHelpers } from "../../../utils/EditProviderHelpers.js";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.js";
import { rethrow } from "../../../utils/crud/index.js";
import { makeStates, setStates } from "../../../utils/hooks/makeStates.js";
import { useInfo } from "../../../utils/hooks/useInfo.js";
import { GroupApi } from "../../../utils/crud/group_api.js";
import { executeWithIntervalAndTimeoutAndFill } from "../../../utils/hooks/pending"

import moment from "moment";

const { createContext, useContext } = require("react");

export const AnalyticsViewContext = createContext();
export function useAnalyticsViewContext() {
    return useContext(AnalyticsViewContext)
}

let default_resource_state = {
    from: '',
    to: moment().local().format('YYYY-MM-DD'),
    statblocks: null
}
const default_secondary_state = {
    errors: {},
    loading: true,
}

const crud = new GroupApi();
const resource_route = '/analytics'
export const AnalyticsShowProvider = ({ children }) => {
    const [searchParams] = useSearchParams();
    const from = searchParams.get('from');
    const to = searchParams.get('to');
    if (from) default_resource_state.from = from;
    if (to) default_resource_state.to = to;
    const [resource_states, resource_setters] = makeStates(default_resource_state)
    const [secondary_state, secondary_setters] = makeStates(default_secondary_state);
    const navigate = useNavigate();
    const info = useInfo();
    const { t } = useLanguageContext();
    const { setLoading, setErrors } = secondary_setters;
    const { statblocks } = resource_states
    const { setStatblocks } = resource_setters

    const pureFetchWithTO = async () => executeWithIntervalAndTimeoutAndFill(() => crud.findAnalyticsAll({ from, to }), 2000, 300000, statblocks, setStatblocks, 'statblocks');

    const fetchAndSet = (
        async () => pureFetchWithTO()
            .then(async (data) => setStates(resource_setters, data, ((k, v) => v)))
            .catch((error) => {
                info(error.message ?? error, 'danger')
            })
    )
    const { setError } = makeHelpers(fetchAndSet, info, t, setErrors, navigate, resource_route)

    useEffectAsync(async () => {
        setStates(secondary_setters, default_secondary_state);
        await fetchAndSet();
        setLoading(false)
    }, []);

    let all_states = {
        ...resource_states,
        ...resource_setters,
        ...secondary_state,
        ...secondary_setters,
        setError,
    }
    return <AnalyticsViewContext.Provider value={{ ...all_states }}>
        {children}
    </AnalyticsViewContext.Provider>

}