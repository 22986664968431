import { Crud } from ".";

export class GroupApi extends Crud {
    constructor() {
        super('admin_sitegroup')
    }
    findAnalytics(id, options) {
        return this.find(id, options, 'findAnalytics')
    }
    findAnalyticsAll(options) {
        return this.find(0, options, 'findAnalyticsAll')
    }
    monthlyVisits(listContext, id, options = {}) {
        return this.get(listContext, { id, ...options }, 'monthlyVisits')
    }
}
