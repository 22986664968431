import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { LineChart, lineElementClasses, markElementClasses } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';

export function Average({ data, xdata }) {
    return (
        <Stack direction="row" sx={{ width: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <LineChart
                    colors={['#006064']}
                    series={[{
                        data: data,
                        label: 'Cumulative total of unique visitors',
                    }]}
                    xAxis={[{
                        scaleType: 'band',
                        data: xdata,
                        // tickLabelStyle: {
                        //     angle: 45,
                        //     textAnchor: 'start',
                        //     fontSize: 12,
                        // }
                    }]}
                    height={200}
                    margin={{ left: 120 }}
                    curve="natural"
                    sx={(theme) => ({
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: theme.palette.divider,

                        [`& .${lineElementClasses.root}`]: {
                            stroke: '#006064',
                            // strokeWidth: 3,
                        },
                        [`& .${markElementClasses.root}`]: {
                            stroke: '#006064',
                            scale: '0.5',
                            fill: '#006064',
                            strokeWidth: 1,
                        },
                    })}
                />
            </Box>
        </Stack>
    );
}

export function Perday({ data, xdata }) {
    return (
        <Stack direction="row" sx={{ width: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <BarChart
                    height={300}
                    margin={{ left: 120 }}
                    xAxis={[{
                        data: xdata,
                        scaleType: 'band',
                        // tickLabelStyle: {
                        //     angle: 45,
                        //     textAnchor: 'start',
                        //     fontSize: 12,
                        // }
                    }]}
                    series={[{
                        data: data,
                        label: 'Unique visitors per day',
                    }]}
                    sx={(theme) => ({
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: theme.palette.divider,
                    })}
                />
            </Box>
        </Stack>
    );
}