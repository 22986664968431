// Функция для выполнения запроса с интервалом и таймаутом, пока в ответе не будет ни одного поля с pending
// При каждой итерации заполняет поля объекта
export async function executeWithIntervalAndTimeoutAndFill(fn, interval, timeout, params, setParams, objectkey = null) {
    const result = await fn(true);
    setParams({ ...params, ...(objectkey ? result[objectkey] : result) });
    if (!Object.values(objectkey ? result[objectkey] : result).some(x => x === 'pending')) return result;
    let totalTime = 0;
    while (totalTime < timeout) {
        await new Promise((r) => setTimeout(r, interval));
        let result = await fn(false);
        setParams({ ...params, ...(objectkey ? result[objectkey] : result) });
        if (Object.values(objectkey ? result[objectkey] : result).some(x => x === 'error')) {
            throw ({ code: 500, message: "Can't load data, please try again!" });
        }
        if (!Object.values(objectkey ? result[objectkey] : result).some(x => x === 'pending')) {
            return result;
        } else {
            totalTime += interval;
            if (totalTime >= timeout) {
                return { is_error: true, error: { message: 'The request is too long, please try to send the same request again.' } };
            }
        }
    }
}

// Функция для выполнения запроса с интервалом и таймаутом, пока в ответе не будет ни одного поля с pending
export async function executeWithIntervalAndTimeout(fn, interval, timeout) {
    return new Promise(async (resolve) => {
        // Первый запрос будет сразу, дальше - по интервалу
        const result = await fn();
        if (!result.pending) resolve(result);
        let totalTime = 0;
        const intervalId = setInterval(async () => {
            const result = await fn();
            if (result.error) {
                clearInterval(intervalId);
                return reject({ code: 500, message: "Can't load data, please try again!" });
            }
            if (!result.pending) {
                clearInterval(intervalId);
                return resolve(result);
            } else {
                totalTime += interval;
                if (totalTime >= timeout) {
                    clearInterval(intervalId);
                    return resolve({ is_error: true, error: { message: 'The request is too long, please try to send the same request again.' } });
                }
            }
        }, interval);
    });
}