import React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { pieArcLabelClasses } from '@mui/x-charts';
import { cyan } from '@mui/material/colors';
import { Box, Typography, Stack } from '@mui/joy';
import { formatPrc } from '../utils';

const cyanPalette = [900, 800, 700, 600, 'A700', 500, 400, 300, 200, 100, 'A400', 'A200', 'A100'].map((k) => { return cyan[k] });
export const PieChartWithLegend = ({ data, colors = cyanPalette }) => {
    return (
        <Box display={'flex'} gap={2} alignItems={{ xs: 'flex-start', sm: 'center' }} width={'fit-content'} flexDirection={{ xs: 'column', sm: 'row' }}>
            <PieChart
                colors={colors}
                slotProps={{
                    itemContent: {
                        sx: (theme) => ({
                            backgroundColor: 'white',
                            ...theme.applyStyles('dark', {
                                backgroundColor: 'black',
                            }),
                        }),
                    },
                    legend: {
                        hidden: true,
                    },
                }}
                series={[
                    {
                        arcLabel: (row) => `${formatPrc(row.value)}`,
                        arcLabelMinAngle: 35,
                        arcLabelRadius: '60%',
                        cx: 100,
                        data: data.map((row) => {
                            let label = row.label;
                            if (row.count !== undefined && row.count !== null) label = `${row.label} - ${row.count}`
                            label += ` (${formatPrc(row.prc)})`;
                            return { label, value: row.prc };
                        }),
                    },
                ]}
                width={300}
                height={200}
                sx={{ [`& .${pieArcLabelClasses.root}`]: { fill: 'white' } }}
            />
            <Stack spacing={1}>
                {data.map((row, index) => (
                    <Typography key={row.label} level="body-sm" display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Box
                            sx={{
                                width: 20,
                                height: 20,
                                borderRadius: 1,
                                marginRight: 1,
                                bgcolor: colors[index % colors.length],
                            }}
                        />
                        <Box display={'flex'} alignItems={'center'} gap={1}>{row.legendLabel}</Box>
                    </Typography>
                ))}
            </Stack>
        </Box>
    );
};