/**
 * Translate description: Sidebar admin-menu
 */

import { useState, Fragment, useEffect, useMemo } from 'react';
import { useLocation, Link as ReactLink } from "react-router-dom";
import { useLanguageContext } from "../../context/LanguageContext";

import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import LeaderboardRoundedIcon from '@mui/icons-material/LeaderboardRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ModeStandbyRoundedIcon from '@mui/icons-material/ModeStandbyRounded';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';
import TrackChangesRoundedIcon from '@mui/icons-material/TrackChangesRounded';
// import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
// import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
// import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
// import TranslateIcon from '@mui/icons-material/Translate';
// import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
// import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
// import TagRoundedIcon from '@mui/icons-material/TagRounded';
// import RssFeedRoundedIcon from '@mui/icons-material/RssFeedRounded';
// import BrandingWatermarkRoundedIcon from '@mui/icons-material/BrandingWatermarkRounded';
// import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
// import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
// import ViewCarouselRoundedIcon from '@mui/icons-material/ViewCarouselRounded';
// import { IconButton, ListSubheader, Stack } from '@mui/joy';
// import { Collapse } from '@mui/material';

function Toggler({ defaultExpanded = false, locationWhereOpened = [], renderToggle, children }) {
  const [open, setOpen] = useState(defaultExpanded);

  useEffect(() => {
    setOpen(locationWhereOpened.includes(location.pathname));
  }, [location.pathname])

  return (
    <Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </Fragment>
  );
}

function Item({ to, title, Icon, location_regex_str }) {
  const { t } = useLanguageContext();
  let regex = useMemo(() => new RegExp(location_regex_str), [location_regex_str])
  if (!Icon) {
    return <ListItem sx={{ mt: '4px' }}>
      <ReactLink to={to} style={linkStyle}>
        <ListItemButton sx={{ pl: 4.7 }} selected={regex.test(location.pathname)}>
          <ListItemContent>
            <Typography level="title-sm" fontWeight={400}>{t(title)}</Typography>
          </ListItemContent>
        </ListItemButton>
      </ReactLink>
    </ListItem>
  }
  return <ListItem>
    <ReactLink to={to} style={linkStyle}>
      <ListItemButton selected={regex.test(location.pathname)}>
        <Icon />
        <ListItemContent>
          <Typography level="title-sm">{t(title)}</Typography>
        </ListItemContent>
      </ListItemButton>
    </ReactLink>
  </ListItem>
}
function Section({ children, title, location_regex_str, Icon }) {
  const { t } = useLanguageContext();
  const [open, setOpen] = useState(false);
  let regex = useMemo(() => new RegExp(location_regex_str), [location_regex_str])
  useEffect(() => {
    setOpen(regex.test(location.pathname))
  }, [location.pathname])
  return <ListItem sx={{ flexDirection: 'column', '--NestedList-marginLeft': 'calc(-1 * var(--ListItem-paddingLeft))', '--NestedList-marginRight': 'calc(-1 * var(--ListItem-paddingRight))', gap: 0 }}>
    <ListItemButton onClick={() => setOpen(prev => !prev)}>
      <Icon />
      <ListItemContent>
        <Typography level="title-sm">{t(title)}</Typography>
      </ListItemContent>
      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </ListItemButton>
    <Box width={'100%'} sx={{ display: 'grid', gridTemplateRows: open ? '1fr' : '0fr', transition: open ? 'all 0.2s ease 0s' : '0.2s ease', '& > *': { overflow: 'hidden' } }}>
      <List sx={{ gap: '4px', '--ListItem-minHeight': '32px', '--ListItem-radius': (theme) => theme.vars.radius.sm, ml: 'var(--NestedList-marginLeft)', mr: 'var(--NestedList-marginRight)', padding: 0 }}>
        {children}
      </List>
    </Box>
  </ListItem>
}
const linkStyle = { textDecoration: 'none', display: 'block', width: '100%' };

export default function SidebarMenu() {
  const { t } = useLanguageContext();
  const location = useLocation();

  return (
    <>
      <List
        size="sm"
        sx={{
          gap: 1,
          '--List-nestedInsetStart': '30px',
          '--ListItem-radius': (theme) => theme.vars.radius.sm,
        }}
      >


        <Section title="Analytics" location_regex_str="^/analytics.*" Icon={LeaderboardRoundedIcon}>
          <Item to="/analytics" title="General" location_regex_str="^(/analytics$)|^(/analytics\?)" />
          <Item to="/analytics/advertisers" title="Domains" location_regex_str="^/analytics/advertiser.*" />
          <Item to="/analytics/groups" title="Site Groups" location_regex_str="^/analytics/group.*" />
          <Item to="/analytics/visitors" title="Visitors" location_regex_str="^/analytics/visitor.*" />
        </Section>
        <Section title="Settings" Icon={SettingsRoundedIcon} location_regex_str="^((/advertiser)|(/group)|(/target_action)|(/user)|(/setting)).*">
          <Item to="/advertisers" title="Domains" location_regex_str="^/advertiser.*" />
          <Item to="/groups" title="Site Groups" location_regex_str="^/group.*" />
          <Item to="/target_actions" title="Target Actions" location_regex_str="^/target_action.*" />
          <Item to="/users" title="Users" location_regex_str="^/user.*" />
          <Item to="/settings" title="General" location_regex_str="^/setting.*" />
        </Section>

        <Item to="/antifraud" Icon={DangerousRoundedIcon} title="Antifraud" location_regex_str="^/antifraud.*" />
        <Item to="/adminlogs" Icon={TrackChangesRoundedIcon} title="Admin's actions log" location_regex_str="^/adminlogs.*" />
      </List>
      <List
        size="sm"
        sx={{
          mt: 'auto',
          flexGrow: 0,
          '--ListItem-radius': (theme) => theme.vars.radius.sm,
          '--List-gap': '4px',
        }}
      >
        <Section title="Knowledge Base" location_regex_str="^/kb.*" Icon={AutoStoriesRoundedIcon}>
          <Item to="/kb/an/adv" title="Domain analytics" location_regex_str="^/kb/an/adv.*" />
          <Item to="/kb/adv" title="Domain settings" location_regex_str="^/kb/adv.*" />
          <Item to="/kb/groups" title="Site groups settings" location_regex_str="^/kb/groups.*" />
          <Item to="/kb/ta" title="Target actions settings" location_regex_str="^/kb/ta.*" />
          {/* <Item to="/kb/af" title="Antifraud" location_regex_str="^/kb/af.*" /> */}
        </Section>
      </List>
    </>
  )
}