import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToAnchor = () => {
  const location = useLocation();
  useEffect(() => {
    const hash = location.hash;
    if (!hash) return;
    const element = document.querySelector(hash);
    if (!element) return;
    element.scrollIntoView({ behavior: 'smooth' });
  }, [location]);
  return null;
};
export default ScrollToAnchor;