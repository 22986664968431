import { Box, Typography, Stack, Skeleton } from '@mui/joy';

export function PieChartSkeleton() {
	return (
		<Box display={'flex'} flexDirection={'row'} alignItems={'center'} width={'fit-content'}>
			<Typography level="body-sm" display={'flex'} flexDirection={'row'} alignItems={'center'} gap={"100px"}>
				<Skeleton variant="circular" sx={{ width: "190px", height: "190px" }} />
				<Stack spacing={1}>
					<Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
						<Skeleton
							variant="rectangular"
							sx={{
								width: 20,
								height: 20,
								marginRight: 1
							}}
						></Skeleton>
						<Skeleton variant='text' sx={{ width: "100px" }}></Skeleton>
					</Box>
					<Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
						<Skeleton
							variant="rectangular"
							sx={{
								width: 20,
								height: 20,
								marginRight: 1
							}}
						></Skeleton>
						<Skeleton variant='text' sx={{ width: "100px" }}></Skeleton>
					</Box>
					<Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
						<Skeleton
							variant="rectangular"
							sx={{
								width: 20,
								height: 20,
								marginRight: 1
							}}
						></Skeleton>
						<Skeleton variant='text' sx={{ width: "100px" }}></Skeleton>
					</Box>
				</Stack>
			</Typography>
		</Box>
	);
}