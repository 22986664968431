import axios from 'axios'
function wrapRequestError(error, error_type = 'unknown') {
    return {
        is_error: true,
        error_type,
        error
    }
}
export function rethrow(value) {
    if (value.is_error) throw value.error
    return value
}
let error_handelers = {
    falseAndLog: (error, msg) => {
        console.error(msg, error)
        return wrapRequestError(false, 'bad_status')
    },
    returnAndLog: (error, msg) => {
        console.error([msg, error])
        let error_data = error.response?.data
        return wrapRequestError(error_data, error_data?'bad_status':'network_error')
    }
}
export class Api {
    constructor(resource, settings) {
        this.resource = resource
        this.settings = settings || {
            errorHandler: error_handelers.returnAndLog
        }
    }
    async query(path, method, options = {}) {
        const url = new URL(`${process.env.REACT_APP_BACK_URL}/${this.resource}/${path}`);
        try {
            const config = { withCredentials: true, headers:{"x-reqtype":"admin"} }
            let data = undefined;
            if (method == 'get') {
                for (let option of Object.keys(options)) {
                    url.searchParams.set(option, options[option])
                }
            }
            else if (['post', 'put', 'delete'].includes(method)) {
                data = options;
            }
            else throw new Error(`Method ${method} is not supported`)
            let params = [url.toString(), data, config].filter(x => x)
            const response = await axios[method](...params);
            return response.data;
        } catch (error) {
            if (error?.response?.status === 401) window.location = `/login`;
            return this.settings.errorHandler(error, `Failed to fetch ${method} ${url}`)
        }
    }
}
export class Crud extends Api {
    async find(id, options = {},path='find') {
        if(id) options.id = id;
        let body = await this.query(path, 'get', options);
        if (body.is_error) {
            return body
        }
        if (body?.data?.length == undefined || body.data.length < 1) {
            let error = new Error("Not found")
            error.code = 404
            error.type = 'not_found'
            return wrapRequestError(error, "not_found")
        }
        return body.data[0]
    }
    async create(data) {
        let body = await this.query(`create`, 'post', data)
        return body
    }
    async get(listContext = {},extra,path='get') {
        let {page,sort,order,filter,limit} = listContext;
        if(!page) page = 1;
        if(!sort) sort = 'id'
        if(!order) order = false
        if(!filter) filter = {}
        if(!limit) limit = 10
        let body = await this.query(path, 'post', {page,sort,order,filter,extra,limit})
        return body
    }
    async getLang(currentLang, options) {
        let { paginator, sorting } = options;
        options.page = 1;
        if (paginator) {
            options.page = paginator.currentPage.toString()
            delete options.paginator;
        }

        options.orderDesc = true
        if (sorting) {
            options.sort = sorting.sort;
            options.orderDesc = sorting.order == "desc"
            delete options.sorting;
        }
        let body = await this.query(`get/${currentLang}`, 'post', options)
        return body
    }
    async update(id, data) {
        let body = await this.query(`update/${id}`, 'put', data)
        return body
    }
    async delete(id){
        let body = await this.query(`delete/${id}`, 'post', {id})
        return body
    }
}